<template>
  <div class="login-container">
    <div class="login-box">
      <div class="title">农产品集采平台后台管理系统</div>
      <el-form ref="loginFormRef" :model="loginForm" :rules="rules">
        <span class="span">账号</span>
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" placeholder="输入账号">
          </el-input>
        </el-form-item>
        <div>
          <span class="span">密码</span>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              show-password
              placeholder="输入密码"
            >
            </el-input>
          </el-form-item>
        </div>
        <div
          style="color: red"
          class="mb-10"
          v-if="typePassWord == LOGIN_PASSWORD['1'].value"
        >
          系统检测到您的密码为弱密码，请您修改密码，新密码需为数字、大写字母、小写字母、或者符号
          （!@#$%^&*()_+-={}[]|:;<>,.?~）中的至少三种的至少12位的组合；
        </div>
        <div
          style="color: red"
          class="mb-10"
          v-if="typePassWord == LOGIN_PASSWORD['2'].value"
        >
          系统检测到您的密码为一次性密码，请您修改密码，新密码需为数字、大写字母、小写字母、或者符号
          （!@#$%^&*()_+-={}[]|:;<>,.?~）中的至少三种的至少12位的组合；
        </div>
        <div v-if="isWeakPassword">
          <span class="span">新密码</span>
          <el-form-item prop="new_password">
            <el-input
              v-model="loginForm.new_password"
              show-password
              placeholder="输入密码"
            >
            </el-input>
          </el-form-item>
          <span class="span">确认密码</span>
          <el-form-item prop="confirm_password">
            <el-input
              v-model="loginForm.confirm_password"
              show-password
              placeholder="输入密码"
            >
            </el-input>
          </el-form-item>
        </div>
        <div v-if="noPassWord" style="color: red" class="mb-10">
          新密码与确认密码不一致，请重新输入；
        </div>
        <el-form-item>
          <el-button
            style="width: 100%"
            type="primary"
            @click="onHandleLogin"
            :loading="loading"
            :disabled="loading"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { LOGIN_PASSWORD } from "@/utils/enum/index.js";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      LOGIN_PASSWORD,
      loading: false,
      ctaObj: null,
      loginForm: {
        username: "",
        password: "",
        new_password: "", // 新密码
        confirm_password: "", // 确认密码
      },
      isWeakPassword: false, // 默认不是弱密
      typePassWord: "0", // 默认正常密码登录
      noPassWord: false, //新密码和确认密码是否一致
      rules: {
        username: [
          { required: true, message: "请输入账号" },
          // { required: true, message: "请输入手机号" },
          // {
          //   pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
          //   message: "请输入正确的手机号格式",
          // },
        ],
        password: [{ required: true, message: "请输入密码" }],
        new_password: [
          { validator: this.validateNewPass, trigger: "blur,change" },
        ],
        confirm_password: [
          { validator: this.validateConfirmPass, trigger: "blur,change" },
        ],
      },
    };
  },
  methods: {
    // 验证新密码 是否必填
    validateNewPass(rule, value, callback) {
      if (this.isWeakPassword && !value) {
        callback(new Error("请输入新密码"));
      } else {
        callback();
      }
    },
    // 验证确认密码 是否必填
    validateConfirmPass(rule, value, callback) {
      if (this.isWeakPassword && !value) {
        callback(new Error("请输入确认密码"));
      } else {
        callback();
      }
    },
    // 修改用户名 重置
    changeUserName(val) {
      console.log(val, "333");
      this.loginForm.new_password = "";
      this.loginForm.confirm_password = "";
      this.isWeakPassword = false;
      this.typePassWord = "0";
      this.noPassWord = false;
    },
    /**
     * 触发登录
     */
    onHandleLogin() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const params = {
            ...this.loginForm,
          };
          // 判断修改密码 新密码和确认密码是否一致
          if (
            this.isWeakPassword &&
            this.loginForm.new_password != this.loginForm.confirm_password
          ) {
            this.noPassWord = true;
            this.loading = false;
            return;
          } else {
            this.noPassWord = false;
          }
          try {
            const { data } = await this.$api.login.login(params);
            console.log("🆒 onHandleLogin", data);
            this.typePassWord = data.is_simple;
            //正常登录
            if (data.is_simple == this.LOGIN_PASSWORD["0"].value) {
              this.isWeakPassword = false;
              sessionStorage.setItem("token", data.token);
              sessionStorage.setItem("userInfo", JSON.stringify(data));
              this.$message({
                message: "登录成功",
                type: "success",
              });
              let arr = data.business_type_code.split(",");
              if (arr.includes("center")) {
                this.$router.push({
                  path: "/menu",
                });
              } else {
                this.$router.push({
                  path: "/meResume",
                });
              }
            } else {
              // 重新输入新密码 确认密码
              this.isWeakPassword = true;
            }
          } catch (err) {
          } finally {
            this.loading = false;
          }
        } else {
          return false;
        }
      });
    },
  },
  created() {
    // console.log(this.$store.state);
  },
};
</script>
<style lang="scss" scoped>
.login-container {
  background-color: #222222;
  background-image: url("../assets/image/login.jpg");
  background-size: cover;
  height: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-direction: column;
  padding: 0px 0 0 378px;
  box-sizing: border-box;
  .login-box {
    width: 417px;
    box-sizing: border-box;
    padding: 34px 45px 37px 45px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 12px;
    // opacity: 0.7;
    border: 1px solid #979797;

    .title {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      text-align: center;
      margin-bottom: 45px;
    }
  }
  .span {
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-bottom: 4px;

    line-height: 21px;
  }
}
</style>
