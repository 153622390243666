// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/image/login.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n\n/** 存放自定义的变量，可全局直接使用 */\n\n/** 主题色 */\n\n/** 字体样式 */\n\n/** 边框样式 */\n\n/** 错误样式 */\n\n/** 警告样式 */\n\n/** 可复用的模块化样式 */\n\n/**\n * 设置多行文本超出隐藏\n * $n 为规定多少行\n*/\n.login-container[data-v-7589b93f] {\n  background-color: #222222;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  padding: 0 0 0 1.96875rem;\n  box-sizing: border-box;\n}\n.login-container .login-box[data-v-7589b93f] {\n  width: 2.171875rem;\n  box-sizing: border-box;\n  padding: 0.177083rem 0.234375rem 0.192708rem 0.234375rem;\n  background: rgba(255, 255, 255, 0.7);\n  border-radius: 0.0625rem;\n  border: 0.005208rem solid #979797;\n}\n.login-container .login-box .title[data-v-7589b93f] {\n  font-size: 0.125rem;\n  font-family: PingFangSC-Medium, PingFang SC;\n  font-weight: 500;\n  color: #333333;\n  text-align: center;\n  margin-bottom: 0.234375rem;\n}\n.login-container .span[data-v-7589b93f] {\n  font-size: 0.078125rem;\n  font-family: PingFangSC-Medium, PingFang SC;\n  font-weight: 500;\n  color: #333333;\n  margin-bottom: 0.020833rem;\n  line-height: 0.109375rem;\n}", ""]);
// Exports
module.exports = exports;
