var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-container" }, [
    _c(
      "div",
      { staticClass: "login-box" },
      [
        _c("div", { staticClass: "title" }, [
          _vm._v("农产品集采平台后台管理系统"),
        ]),
        _c(
          "el-form",
          {
            ref: "loginFormRef",
            attrs: { model: _vm.loginForm, rules: _vm.rules },
          },
          [
            _c("span", { staticClass: "span" }, [_vm._v("账号")]),
            _c(
              "el-form-item",
              { attrs: { prop: "username" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "输入账号" },
                  model: {
                    value: _vm.loginForm.username,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "username", $$v)
                    },
                    expression: "loginForm.username",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("span", { staticClass: "span" }, [_vm._v("密码")]),
                _c(
                  "el-form-item",
                  { attrs: { prop: "password" } },
                  [
                    _c("el-input", {
                      attrs: { "show-password": "", placeholder: "输入密码" },
                      model: {
                        value: _vm.loginForm.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "password", $$v)
                        },
                        expression: "loginForm.password",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.typePassWord == _vm.LOGIN_PASSWORD["1"].value
              ? _c(
                  "div",
                  { staticClass: "mb-10", staticStyle: { color: "red" } },
                  [
                    _vm._v(
                      " 系统检测到您的密码为弱密码，请您修改密码，新密码需为数字、大写字母、小写字母、或者符号 （!@#$%^&*()_+-={}[]|:;<>,.?~）中的至少三种的至少12位的组合； "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.typePassWord == _vm.LOGIN_PASSWORD["2"].value
              ? _c(
                  "div",
                  { staticClass: "mb-10", staticStyle: { color: "red" } },
                  [
                    _vm._v(
                      " 系统检测到您的密码为一次性密码，请您修改密码，新密码需为数字、大写字母、小写字母、或者符号 （!@#$%^&*()_+-={}[]|:;<>,.?~）中的至少三种的至少12位的组合； "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.isWeakPassword
              ? _c(
                  "div",
                  [
                    _c("span", { staticClass: "span" }, [_vm._v("新密码")]),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "new_password" } },
                      [
                        _c("el-input", {
                          attrs: {
                            "show-password": "",
                            placeholder: "输入密码",
                          },
                          model: {
                            value: _vm.loginForm.new_password,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "new_password", $$v)
                            },
                            expression: "loginForm.new_password",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "span" }, [_vm._v("确认密码")]),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "confirm_password" } },
                      [
                        _c("el-input", {
                          attrs: {
                            "show-password": "",
                            placeholder: "输入密码",
                          },
                          model: {
                            value: _vm.loginForm.confirm_password,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "confirm_password", $$v)
                            },
                            expression: "loginForm.confirm_password",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.noPassWord
              ? _c(
                  "div",
                  { staticClass: "mb-10", staticStyle: { color: "red" } },
                  [_vm._v(" 新密码与确认密码不一致，请重新输入； ")]
                )
              : _vm._e(),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "primary",
                      loading: _vm.loading,
                      disabled: _vm.loading,
                    },
                    on: { click: _vm.onHandleLogin },
                  },
                  [_vm._v("登录")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }